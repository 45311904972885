
<template>
  <div class="body">
    <loading v-show="loadingisShow" />
    <Header></Header>
    <sideframe></sideframe>
    <div class="index1">
      <breadcrumb :titleData="titleData"></breadcrumb>
      <hr style="min-width: 1200px" />
      <el-row style="text-align: center; margin: 20px auto; width: 90%">
        <!-- <div id="myChart" class="main" style="width: 1150px;height:200px;"></div> -->
        <el-col
          :span="2"
          style="
            width: 100%;
            height: 50%;
            margin: 50px 5px 50px 0;
            background-color: #f5f5f5;
            float: left;
          "
        >
          &nbsp;
          <span style="font-size: 23px; font-weight: bolder; color: #0b1013">
            {{ childdata2.name }}{{ childdata2.comprehensivetypeName }}指数展示
          </span>
          <div id="main1" class="main" style="height: 500px; width: 90%"></div>
        </el-col>
        <el-col
          :span="2"
          style="
            width: 100%;
            height: 50%;
            margin: 50px 5px 50px 0;
            background-color: #f5f5f5;
            float: left;
          "
        >
          &nbsp;
          <span style="font-size: 23px; font-weight: bolder; color: #0b1013">
            {{ childdata2.name
            }}{{ childdata2.comprehensivetypeName }}子指数展示
          </span>
          <div id="main" class="main" style="height: 500px; width: 90%"></div>
        </el-col>
        <el-col
          :span="2"
          style="
            width: 100%;
            height: 50%;
            margin: 50px 5px 50px 0;
            background-color: #f5f5f5;
            float: left;
          "
        >
          &nbsp;
          <span style="font-size: 23px; font-weight: bolder; color: #0b1013">
            <p style="font-size: 14px; font-weight: normal; margin: 0">
              （选择想要查看的子指数下面的孙指数）
            </p>
          </span>
          <div class="main" style="width: 90%; height: 20px">
            &nbsp;
            <span style="font-size: 16px; font-weight: normal; color: #0b1013">
              子指数类型选择：
            </span>
            <template>
              <el-select v-model="comprehensive.typeName" placeholder="请选择">
                <el-option
                  v-for="item in comprehensive"
                  :key="item.id"
                  :label="item.typeName"
                  :value="item.id"
                  @click.native="setComprehensiveId(item.id, item.typeName)"
                >
                </el-option>
              </el-select>
            </template>
          </div>
          <span style="font-size: 23px; font-weight: bolder; color: #0b1013">
            {{ childdata2.name
            }}{{ childdata2.comprehensivetypeName }}孙指数展示
          </span>
          <div
            id="main3"
            class="main"
            style="height: 600px; width: 90%"
          ></div> </el-col
      ></el-row>
    </div>
    <flooter></flooter>
  </div>
</template>

      

<script>
import Flooter from "../flooter.vue";
import Header from "../Header.vue";
import Sideframe from "../../components/sideframe.vue";
import * as echarts from "echarts";
import Cookies from "js-cookie";
import "echarts-gl";
import china from "../../assets/Json/china.json";
import { constants } from "os";
import loading from "../../components/loading.vue";
import Breadcrumb from "../../components/breadcrumb.vue";
import { nanoid } from "nanoid";

export default {
  components: { Header, Flooter, Sideframe, loading, Breadcrumb },
  data() {
    return {
      Comprehensive: {
        pid: 0,
      },
      titleData: {
        titleName: "人力资本化指数展示",
        data: [
          {
            id: nanoid(),
            name: "人力资本化",
            href: "",
          },
          {
            id: nanoid(),
            name: "人力资本化指数展示",
            href: "",
          },
          {
            id: nanoid(),
            name: "",
            href: "",
          },
        ],
      },
      pid: 0,
      loadingisShow: true,
      countriess: [],
      countries1: [],
      countries2: [],
      comprehensivedata: [],
      loading: false,
      childdata2: [],
      comprehensive: [],
    };
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.gettoken();
    this.getuserData();
    this.getAllComprehensive();
    this.titleData.data[1].href = this.$route.query.path;
    this.titleData.data[2].name =
      this.childdata2.name + this.childdata2.comprehensivetypeName;
  },
  // 挂载结束状态(里面是操作)
  mounted() {
    this.$nextTick(() => {
      this.getmdata();
      this.getSCE();
    });
  },
  // 里面的函数只有调用才会执行
  methods: {
    gettoken() {
      let atoken = Cookies.get("token");
      if (atoken !== null && atoken !== undefined) {
        this.token = atoken;
      } else {
        this.userData = null;
        this.token = null;
      }
    },
    async getuserData() {
      if (this.token == null) {
      } else {
        let res = await this.axios.get("/basicUser/tokenByUser", {
          params: {
            token: this.token,
          },
        });
        if (res.code == 200) {
          this.userData = res.object;
        } else {
          this.userData = null;
        }
      }
    },
    setComprehensiveId(data, name) {
      this.loadingisShow = !this.loadingisShow;
      this.pid = data;
      this.getGSdata();
    },
    //获取所有子指数类型
    async getAllComprehensive() {
      let childdata1 = JSON.parse(this.$route.query.cdata);
      this.childdata2 = childdata1;
      this.axios
        .get("/humanCapital/selectComprehensive", {
          params: {
            pid: this.childdata2.pid,
          },
        })
        .then((res) => {
          this.comprehensive = res.object;
        });
    },
    //子指数展示
    async getSCE() {
      let chartDom = document.getElementById("main");
      var rateChart = echarts.getInstanceByDom(document.getElementById("main"));
      if (rateChart != null && rateChart != "" && rateChart != undefined) {
        rateChart.dispose(); //销毁
      }
      rateChart = echarts.init(chartDom);
      var option;

      let res = await this.axios.get("/humanCapital/selectIndex", {
        params: {
          name: this.childdata2.name,
          pid: this.childdata2.pid,
        },
      });
      {
        if (res) {
          let res1 = await this.axios.get("/humanCapital/selectComprehensive", {
            params: {
              pid: this.childdata2.pid,
            },
          });
          this.loadingisShow = !this.loadingisShow;
          this.countriess = res1.object;
          this.countriess.forEach((item) => {
            this.countries1.push(item.typeName);
          });
          let selected = {};

          let countries = this.countries1;
          for (let i = 0; i < countries.length; i++) {
            if (i === 0) {
              selected[countries[i]] = true;
            } else {
              selected[countries[i]] = false;
            }
          }
          let datasetWithFilters = [];
          let seriesList = [];
          echarts.util.each(countries, function (name) {
            let datasetId = "dataset_" + name;
            datasetWithFilters.push({
              id: datasetId,
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "particular", gte: 1980 },
                    { dimension: "comprehensive", "=": name },
                  ],
                },
              },
            });
            seriesList.push({
              type: "line",
              datasetId: datasetId,
              showSymbol: false,
              name: name,
              endLabel: {
                show: true,
                formatter: function (params) {
                  return params.value[1] + ": " + params.value[0];
                },
              },
              labelLayout: {
                moveOverlap: "shiftY",
              },
              emphasis: {
                focus: "series",
              },
              encode: {
                x: "particular",
                y: "value",
                label: ["name", "value"],
                itemName: "particular",
                tooltip: ["value"],
              },
            });
          });
          option = {
            dataset: [
              {
                id: "dataset_raw",
                source: res.object,
              },
              ...datasetWithFilters,
            ],
            // title: {
            //   text: '国家人力资本化数据'
            // },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: "none",
                },
                // restore: {},
                saveAsImage: {},
              },
            },
            legend: {
              data: this.countries1,
              // selectedMode: 'single',
              selected: selected,
            },

            tooltip: {
              order: "valueDesc",
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              nameLocation: "middle",
            },
            yAxis: {
              name: "value",
            },
            grid: {
              right: 140,
            },
            series: seriesList,
          };
          rateChart.setOption(option);
          window.addEventListener("resize", () => {
            rateChart.resize();
          });
        }
      }
    },
    //指数展示
    async getmdata() {
      let childdata1 = JSON.parse(this.$route.query.cdata);
      this.childdata2 = childdata1;
      var chartDom = document.getElementById("main1");
      var myChart = echarts.getInstanceByDom(document.getElementById("main1"));
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose(); //销毁
      }
      var myChart = echarts.init(chartDom);
      var option;
      let res = await this.axios.get("/humanCapital/countryPid", {
        params: {
          name: this.childdata2.name,
          comprehensiveId: this.childdata2.comprehensiveId,
        },
      });
      {
        if (res) {
          let countries = [];
          countries.push(this.childdata2.comprehensivetypeName);
          let datasetWithFilters = [];
          let seriesList = [];
          echarts.util.each(countries, function (name) {
            let datasetId = "dataset_" + name;
            datasetWithFilters.push({
              id: datasetId,
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [
                    { dimension: "particular", gte: 1980 },
                    { dimension: "comprehensive", "=": name },
                  ],
                },
              },
            });
            seriesList.push({
              type: "line",
              datasetId: datasetId,
              showSymbol: false,
              name: name,
              endLabel: {
                show: true,
                formatter: function (params) {
                  return params.value[1] + ": " + params.value[0];
                },
              },
              labelLayout: {
                moveOverlap: "shiftY",
              },
              emphasis: {
                focus: "series",
              },
              encode: {
                x: "particular",
                y: "value",
                label: ["name", "value"],
                itemName: "particular",
                tooltip: ["value"],
              },
            });
          });
          option = {
            dataset: [
              {
                id: "dataset_raw",
                source: res.object,
              },
              ...datasetWithFilters,
            ],
            // title: {
            //   text: '国家人力资本化数据'
            // },
            toolbox: {
              feature: {
                dataZoom: {
                  yAxisIndex: "none",
                },
                // restore: {},
                saveAsImage: {},
              },
            },
            tooltip: {
              order: "valueDesc",
              trigger: "axis",
            },
            xAxis: {
              type: "category",
              nameLocation: "middle",
            },
            yAxis: {
              name: "value",
            },
            grid: {
              right: 140,
            },
            series: seriesList,
          };
          myChart.setOption(option);
          window.addEventListener("resize", () => {
            myChart.resize();
          });
        }
      }
    },
    //孙子数展示
    async getGSdata() {
      let chartDom = document.getElementById("main3");
      var myChart = echarts.getInstanceByDom(document.getElementById("main3"));
      if (myChart != null && myChart != "" && myChart != undefined) {
        myChart.dispose(); //销毁
      }

      myChart = echarts.init(chartDom);
      var option;
      let res = await this.axios.get("/humanCapital/selectIndex", {
        params: {
          name: this.childdata2.name,
          pid: this.pid,
        },
      });
      if (res) {
        this.loadingisShow = !this.loadingisShow;
        this.axios
          .get("/humanCapital/selectComprehensive", {
            params: {
              pid: this.pid,
            },
          })
          .then((res1) => {
            let data = res.object;
            this.countriess = res1.object;
            this.countries2 = [];
            this.countriess.forEach((item) => {
              this.countries2.push(item.typeName);
            });
            let countries = this.countries2;
            let selected = {};
            for (let i = 0; i < countries.length; i++) {
              if (i === 0) {
                selected[countries[i]] = true;
              } else {
                selected[countries[i]] = false;
              }
            }
            let datasetWithFilters = [];
            let seriesList = [];
            echarts.util.each(countries, function (name) {
              let datasetId = "dataset_" + name;
              datasetWithFilters.push({
                id: datasetId,
                fromDatasetId: "dataset_raw",
                transform: {
                  type: "filter",
                  config: {
                    and: [
                      { dimension: "particular", gte: 1980 },
                      { dimension: "comprehensive", "=": name },
                    ],
                  },
                },
              });
              seriesList.push({
                type: "line",
                datasetId: datasetId,
                showSymbol: false,
                name: name,
                endLabel: {
                  show: true,
                  formatter: function (params) {
                    return params.value[1] + ": " + params.value[0];
                  },
                },
                labelLayout: {
                  moveOverlap: "shiftY",
                },
                emphasis: {
                  focus: "series",
                },
                encode: {
                  x: "particular",
                  y: "value",
                  label: ["name", "value"],
                  itemName: "particular",
                  tooltip: ["value"],
                },
              });
            });
            option = {
              dataset: [
                {
                  id: "dataset_raw",
                  source: res.object,
                },
                ...datasetWithFilters,
              ],
              // title: {
              //   text: '国家人力资本化数据'
              // },
              toolbox: {
                feature: {
                  dataZoom: {
                    yAxisIndex: "none",
                  },
                  // restore: {},
                  saveAsImage: {},
                },
              },
              legend: {
                data: this.countries2,
                // selectedMode: 'single'
                selected: selected,
              },
              tooltip: {
                order: "valueDesc",
                trigger: "axis",
              },
              xAxis: {
                type: "category",
                nameLocation: "middle",
              },
              yAxis: {
                name: "value",
              },
              grid: {
                right: 140,
              },
              series: seriesList,
            };
            myChart.setOption(option);
            window.addEventListener("resize", () => {
              myChart.resize();
            });
          });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-link.el-link--default:hover {
  color: rgb(218, 185, 107);
}
::v-deep .el-drawer.btt,
.el-drawer.ttb {
  height: 100% !important;
  width: 100%;
}
.main {
  padding: 10px 30px 30px 30px;
}
#headpage {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  background-color: rgb(51, 51, 51);
}
html,
body {
  height: 100%;
}
.div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
::v-deep .el-breadcrumb__inner:hover {
  color: rgb(218, 185, 107);
}
.el-breadcrumb {
  font-size: 16px;
  margin-right: 40%;
  float: right;
  height: 90px;
  line-height: 90px;
}
::v-deep .active {
  background-color: rgba(51, 51, 51, 0.9) !important;
}
h2 {
  text-align: left;
  padding: 10px 0 0 350px;
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-weight: lighter;
  font-size: 36px;
}
.background {
  background-color: #f7f7f7;
  height: 100px;
}
hr {
  margin: 0;
  margin-bottom: 8px;
}
.stbox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.stbgc {
  background-color: #fff;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  padding: 0px 16px;
}
.stsearch {
  text-align: center;
}
.text-c {
  text-align: center;
}
.st-gbox {
  background-color: #fff;
  margin-top: 20px;
  border-radius: 5px;
  height: 30vh;
  box-sizing: border-box;
  padding: 10px;
}
.cavasbox {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.paybox {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  height: 32vh;
}
</style>